import { ForwardRefComponent } from 'framer-motion';
import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import { useMouseHovered } from 'react-use';
import { buttonAnimationSpace, springDefault } from '~/theme/animations/baseAnimations';
import { ButtonIcon } from './components/ButtonIcon';
import { Content } from './components/content';
import { calculateButtonBoundaries } from './hooks/calculateButtonBoundaries';
import {
    StyledButton,
    StyledButtonContent,
    StyledButtonWrapper,
    StyledCenteredIconButtonIcon,
    StyledCenteredIconButtonText,
    StyledLinkComponent,
} from './styled';

export const buttonVariants = ['Red', 'Transparent', 'Plain', 'Ghost'] as const;
export const buttonShades = ['dark', 'light'] as const;
export const buttonSizes = ['Big', 'Small', 'Inline', 'Icon'] as const;
export const ButtonArea = ['auto', '100'] as const;
export type ButtonVariant = (typeof buttonVariants)[number];
export type ButtonShade = (typeof buttonShades)[number];
export type ButtonSize = (typeof buttonSizes)[number];
export type ButtonArea = (typeof ButtonArea)[number];

export interface ButtonProps {
    /**
     * Which theme variant to display button as
     * primary: solid background, without border
     * secondary: transparent background, with border
     * tertiary: transparent background, without border
     */
    variant?: ButtonVariant;

    /**
     * Which shade the button has. Useful for background distinction
     */
    shade?: ButtonShade;

    size?: ButtonSize;

    area?: ButtonArea;

    /**
     * Append an icon component to the button contents
     */
    icon?: ReactNode | string;

    /**
     * Optional label on icon to aid assistive technology users further (if the button text is not descriptive enough)
     */
    iconLabel?: string;

    /**
     * Whitespace in icon svgs will sometimes result in alignment issues at the edge of the button (ie. the
     * distance from icon graphics to the edge of the button seems too wide).
     *
     * Adjust this number from 0 to 1 to subtract a percentage of the width of the icon at the edge.
     */
    iconOffsetEdge?: number;

    /**
     * Place the icon before or after content
     */
    iconAlignment?: 'start' | 'end' | 'center';

    iconSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

    /**
     * Description of what the button does, this is for screen readers
     * and should be used when content does not describe the action
     */
    description?: string;
    children?: ReactNode;
    disableHoverAnimation?: boolean;
    animationSpace?: number;
    animationDamping?: number;
    animationStiffness?: number;

    style?: CSSProperties;

    disabled?: boolean;

    onClick?: (e?: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void | Promise<void>;

    href?: string;

    as?: string;

    target?: string;
}

/*


    onClick?: () => void;

    disabled?: boolean;

    href?: string;

    type?: string; 

    as?: string;

    target?: string;
*/
/**
 * Handles all the button styles across the site.
 *
 * The proper way to use as link is to combine with next/link. This hooks into the router and performance optimisations.
 * @example
 * <NextLink href="#" passHref>
 *   <Button {...props} as="a" />
 * </NextLink>
 */

export const Button: ForwardRefComponent<
    HTMLButtonElement | HTMLAnchorElement,
    ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = React.forwardRef(
    (
        {
            variant = 'Red',
            shade = 'dark',
            size = 'Big',
            area = 'auto',
            iconAlignment = 'end',
            icon,
            iconLabel,
            iconOffsetEdge = 0,
            iconSize,
            description,
            children,
            disableHoverAnimation,
            animationSpace = buttonAnimationSpace,
            animationDamping = springDefault.damping,
            animationStiffness = springDefault.stiffness,
            style = {},
            ...restProps
        },
        ref,
    ) => {
        const ComponentElm = restProps.href ? StyledLinkComponent : StyledButton;
        const disabledAnimation = disableHoverAnimation || variant === 'Plain' || size === 'Inline';
        const buttonContainerRef = useRef<HTMLDivElement>(null);
        const { elX, elY, elW, elH } = useMouseHovered(buttonContainerRef, {
            whenHovered: true,
        });
        const [mousePosition, setMousePosition] = useState<{
            x: number;
            y: number;
            textX: number;
            textY: number;
        }>({
            x: 0,
            y: 0,
            textX: 0,
            textY: 0,
        });

        const handleMouseMove = () => {
            if (disabledAnimation) {
                return;
            }
            setMousePosition({
                x: calculateButtonBoundaries(elX - elW / 2, animationSpace),
                y: calculateButtonBoundaries(elY - elH / 2, animationSpace),
                textX: calculateButtonBoundaries(elX - elW / 2, animationSpace * 0.4),
                textY: calculateButtonBoundaries(elY - elH / 2, animationSpace * 0.4),
            });
        };

        const handleMouseLeave = () => {
            if (disabledAnimation) {
                return;
            }
            setMousePosition({
                x: 0,
                y: 0,
                textX: 0,
                textY: 0,
            });
        };

        return (
            <StyledButtonWrapper
                ref={buttonContainerRef}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                area={area}
            >
                <ComponentElm
                    ref={ref}
                    variant={variant}
                    shade={shade}
                    size={size}
                    {...restProps}
                    animate={{
                        x: mousePosition.x,
                        y: mousePosition.y,
                    }}
                    transition={{
                        type: 'spring',
                        damping: animationDamping,
                        stiffness: animationStiffness,
                    }}
                    style={style}
                >
                    <StyledButtonContent
                        size={size}
                        animate={{
                            x: mousePosition.textX,
                            y: mousePosition.textY,
                        }}
                        transition={{
                            type: 'spring',
                            damping: animationDamping,
                            stiffness: animationStiffness,
                        }}
                    >
                        {iconAlignment !== 'center' ? (
                            <>
                                {!!icon && iconAlignment === 'start' && (
                                    <ButtonIcon
                                        iconSize={iconSize}
                                        icon={icon}
                                        iconLabel={iconLabel}
                                        iconAlignment={iconAlignment}
                                        iconOffsetEdge={iconOffsetEdge}
                                    />
                                )}
                                {description ? (
                                    <Content
                                        size={size}
                                        description={description}
                                        children={children}
                                    />
                                ) : (
                                    <>{children}</>
                                )}
                                {!!icon && iconAlignment === 'end' && (
                                    <ButtonIcon
                                        iconSize={iconSize}
                                        icon={icon}
                                        iconLabel={iconLabel}
                                        iconAlignment={iconAlignment}
                                        iconOffsetEdge={iconOffsetEdge}
                                    />
                                )}
                            </>
                        ) : (
                            <>
                                <StyledCenteredIconButtonText
                                    className={'centered-icon-button-text'}
                                >
                                    {children}
                                </StyledCenteredIconButtonText>
                                <StyledCenteredIconButtonIcon>
                                    <ButtonIcon icon={icon} iconLabel={iconLabel} iconSize={'lg'} />
                                </StyledCenteredIconButtonIcon>
                            </>
                        )}
                    </StyledButtonContent>
                </ComponentElm>
            </StyledButtonWrapper>
        );
    },
);

Button.displayName = 'Button';
